import React from "react";
import { graphql } from "gatsby";
import ReactMarkDown from "react-markdown";
import {
  withStyles,
  Grid,
  WithStyles,
  createStyles,
  Hidden
} from "@material-ui/core";
import { compose } from "recompose";
import { Container } from "../components/general";
import MainLayout from "../components/layout/MainLayout";
import SEO from "../components/SEO";
import { SpecificTheme } from "../theme/default";
import Typography from "@material-ui/core/Typography";
import BackgroundFront from "../components/general/BackgroundFront";
import { get } from "../helpers/data";
import { HomePageQuery } from "./__generated__/HomePageQuery";
import Footer from "../components/general/Footer";
import { PageProps } from "../typings";
import { Image } from "../components/image/Image";

type IndexProps = {} & PageProps<HomePageQuery>;

const styles = (theme: SpecificTheme) =>
  createStyles({
    introductionDiv: {
      [theme.breakpoints.down("sm")]: {
        minHeight: "49vh"
      }
    },
    // Boxes related
    root: {},
    introContainer: {},
    sectionHeader: {
      color: theme.palette.common.white,
      marginTop: "2rem",
      fontSize: "1.75rem"
    },
    sectionText: {
      "& p": {
        color: theme.palette.common.white,
        marginTop: 0,
        marginBottom: 0,
        fontSize: "1.2rem"
      },
      "& p:last-child": {
        [theme.breakpoints.down("sm")]: {
          marginBottom: "2rem"
        }
      }
    },
    mainImage: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "40px 0"
    },
    boxesLeftGrid: {
      [theme.breakpoints.up("md")]: {
        paddingRight: "20px"
      }
    },
    boxesRightGrid: {},
    header: {
      color: "#fff",
      [theme.breakpoints.only("md")]: {
        fontSize: "3rem",
        marginTop: "3rem"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "4.5rem"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "6rem"
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "2rem"
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "7rem"
      },
      fontSize: "5rem"
    }
  });

type ComponentProps = IndexProps & WithStyles<typeof styles>;

/**
 * Index page
 * @param classes
 * @param match
 * @param homepage
 * @param data
 * @constructor
 */
const IndexPage: React.FC<ComponentProps> = ({ classes, data, location }) => {
  const { allContentfulHomePage } = data;
  const homepage = get(allContentfulHomePage, "edges")!.map(edge =>
    edge ? edge.node : null
  )[0];

  const sections = get(homepage, "sections");

  const mobileRoadImage = get(data, "contentfulImages", "image", "fluid");

  if (homepage) {
    return (
      <MainLayout location={location} title="Home">
        <div className={classes.root}>
          <Container variant="expandedRight" padding color="primary">
            <Grid container spacing={0} className={classes.introContainer}>
              {mobileRoadImage && (
                <Hidden mdUp>
                  <Image
                    image={{
                      ...data.contentfulImages.image,
                      style: { width: "100%" }
                    }}
                  />
                  {/*<GatsbyImage
                    alt={get(data, "contentfulImages", "image", "description")!}
                    fluid={mobileRoadImage}
                    style={{ width: "100%" }}
                  />*/}
                </Hidden>
              )}

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7}
                className={classes.introductionDiv}
              >
                <Typography variant={"h1"} className={classes.header}>
                  {homepage.introductionTitle}
                </Typography>
                {sections &&
                  sections.map(section => {
                    if (section) {
                      const sectionText = get(
                        section,
                        "text",
                        "childMarkdownRemark",
                        "rawMarkdownBody"
                      );
                      return (
                        <React.Fragment key={section.title!}>
                          <Typography
                            variant="h4"
                            className={classes.sectionHeader}
                          >
                            {section.title}
                          </Typography>
                          <ReactMarkDown
                            source={sectionText ? sectionText : ""}
                            className={classes.sectionText}
                          />
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  md={6}
                  lg={5}
                  // minus height of bar
                  style={{ minHeight: "82vh" }}
                >
                  <BackgroundFront isNight={false} />
                </Grid>
              </Hidden>
            </Grid>
          </Container>
          <Footer />
        </div>

        {/* SEO, head params here */}
        <SEO
          general={{
            language: "de",
            contentType: "website",
            path: location.pathname,
            title: data.contentfulPages.seoTitle,
            description: data.contentfulPages.seoDescription
          }}
        />
      </MainLayout>
    );
  }
  return null;
};

export default compose<ComponentProps, {}>(withStyles(styles))(IndexPage);

export const pageQuery = graphql`
  query HomePageQuery {
    contentfulPages(page: { eq: "Home" }) {
      seoTitle
      seoDescription
    }
    allContentfulHomePage {
      edges {
        node {
          introductionTitle
          introduction {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          sections {
            title
            text {
              childMarkdownRemark {
                html
                rawMarkdownBody
              }
            }
          }
        }
      }
    }

    contentfulImages(name: { eq: "roadmobile" }) {
      id
      name
      image {
        title
        description
        fluid(maxWidth: 1000) {
          ...ContentfulFluidImage
        }
      }
    }

    mainImage: imageSharp(original: { src: { regex: "/home/" } }) {
      fixed(width: 400, traceSVG: { color: "lightgray" }) {
        ...GatsbyImageSharpFixed_tracedSVG
      }
    }

    ...logoFragment
  }
`;
