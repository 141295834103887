import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { SpecificTheme } from "../../theme/default";
import { get } from "../../helpers/data";
import { FrontImage } from "./__generated__/FrontImage";
import { compose } from "recompose";

type PublicProps = {
  isNight: boolean;
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    image: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.specific.backgroundFront,
      backgroundPosition: "top left",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    }
  });

type ComponentProps = PublicProps & WithStyles<typeof styles>;

const BackgroundSection: React.FC<ComponentProps> = ({
  classes,
  isNight,
  children
}) => {
  const data = useStaticQuery<FrontImage>(
    graphql`
      query FrontImage {
        roadcrop: contentfulImages(name: { eq: "roadcrop" }) {
          id
          name
          image {
            title
            description
            fluid(maxWidth: 2500) {
              ...ContentfulFluidImage
            }
          }
        }

        dayImage: imageSharp(original: { src: { regex: "/MatterhornTag/" } }) {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }

        nightImage: imageSharp(
          original: { src: { regex: "/MatterhornTag/" } }
        ) {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    `
  );

  const nightImage = get(data, "roadcrop", "image", "fluid");
  const dayImage = get(data, "roadcrop", "image", "fluid");

  if (nightImage && dayImage) {
    // Retrieve corresponding image
    const imageData: any = isNight ? nightImage : dayImage;

    return (
      <BackgroundImage
        Tag="section"
        className={classes.image}
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
        {children}
      </BackgroundImage>
    );
  }

  return null;
};

export default compose<ComponentProps, PublicProps>(withStyles(styles))(
  BackgroundSection
);
